import { createClient } from "wagmi"
import { configureChains, InjectedConnector } from "@wagmi/core"
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet"
import { WalletConnectConnector } from "wagmi/connectors/walletConnect"
import { MetaMaskConnector } from "wagmi/connectors/metaMask"

import { WAGMI_CHAINS, WAGMI_PROVIDERS } from "web3/constants/rpc"
import type { EIP6963ProviderDetail } from "web3/stores/eip6963"
import { DetectedConnector } from "web3/DetectedConnector"

// @ts-expect-error nothing here
const { chains, provider } = configureChains(WAGMI_CHAINS, WAGMI_PROVIDERS)

// eslint-disable-next-line import/no-unused-modules
export const wagmiClientConnectors = (
  detectedConnectors: EIP6963ProviderDetail[],
) => {
  const dConnectors = detectedConnectors.map(
    (dc) =>
      new DetectedConnector({
        provider: dc.provider,
        options: {
          name: dc.info.rdns,
          uuid: dc.info.uuid,
        },
        chains: WAGMI_CHAINS,
      }),
  )

  return createClient({
    autoConnect: true,
    provider,
    connectors: [
      new MetaMaskConnector({ chains }),
      new WalletConnectConnector({
        // https://github.com/wevm/wagmi/issues/3012#issuecomment-1721744364
        // tl;dr support `eth_signTypedData_v4` for wallet connect by removing
        // from `configureChains`
        chains: WAGMI_CHAINS,
        options: {
          projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
          showQrModal: true,
        },
      }),
      new CoinbaseWalletConnector({
        chains,
        options: {
          appName: "Tally",
        },
      }),
      ...dConnectors,
    ],
  })
}

export const wagmiClient = createClient({
  autoConnect: true,
  provider,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      // https://github.com/wevm/wagmi/issues/3012#issuecomment-1721744364
      // tl;dr support `eth_signTypedData_v4` for wallet connect by removing
      // from `configureChains`
      chains: WAGMI_CHAINS,
      options: {
        projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
        showQrModal: true,
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "Tally",
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: (detectedName: string | string[]) =>
          typeof detectedName === "string"
            ? detectedName
            : detectedName.join(", "),
        shimDisconnect: true,
      },
    }),
  ],
})
