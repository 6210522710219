import { useSyncExternalStore } from "react"
import type { Ethereum } from "@wagmi/core"

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface WindowEventMap {
    "eip6963:announceProvider": CustomEvent<EIP6963AnnounceProviderEvent>
  }
}

type EIP6963ProviderInfo = {
  icon: string
  name: string
  rdns: string
  uuid: string
}

export type EIP6963ProviderDetail = {
  info: EIP6963ProviderInfo
  provider: Ethereum
}

type EIP6963AnnounceProviderEvent = {
  detail?: EIP6963ProviderDetail
}

let providers: EIP6963ProviderDetail[] = []

const store = {
  getSnapshot: () => providers,

  subscribe: (cb: () => void) => {
    function handleAnnouncement(event: EIP6963AnnounceProviderEvent) {
      if (!event.detail) {
        return
      }

      if (providers.some((p) => p.info.uuid === event.detail?.info.uuid)) {
        return
      }

      providers = providers.concat(event.detail)
      cb()
    }

    window.addEventListener(
      "eip6963:announceProvider",
      handleAnnouncement as EventListener,
    )
    window.dispatchEvent(new Event("eip6963:requestProvider"))

    return () =>
      window.removeEventListener(
        "eip6963:announceProvider",
        handleAnnouncement as EventListener,
      )
  },

  getServerSnapshot: () => providers,
}

export function useEIP6963Store() {
  return useSyncExternalStore(
    store.subscribe,
    store.getSnapshot,
    store.getServerSnapshot,
  )
}
