import {
  createContext,
  useContext,
  type ReactNode,
  useState,
  useCallback,
  useEffect,
} from "react"
import { useWallet } from "@solana/wallet-adapter-react"
import type { PublicKey } from "@solana/web3.js"
import type BN from "bn.js"

import {
  type TransactionStatus,
  useMultigovStaking,
  WORMHOLE_MINT,
} from "web3/solana/wormhole-staking/useMultigovStaking"
import { useSolanaTransactionToast } from "web3/solana/providers/SolanaTransactionToastProvider"
import { WHTokenBalance } from "web3/solana/wormhole-staking/WHTokenBalance"
import { useTokenBalance } from "web3/solana/hooks/useTokenBalance"

type StakeAccountContextType = {
  stakeAccount: PublicKey | null
  stakeAccountCustodyAddress: PublicKey | null
  balance: number
  isLoading: boolean
  error: Error | null
  checkAccount: () => Promise<void>
  checkStakeAccountCustody: () => Promise<void>
  createStakeAccount: () => Promise<string | undefined>
  createStakeAccountAndTransfer: (amount: string) => Promise<string | undefined>
  transferToStakingAccount: (amount: string) => Promise<string | undefined>
  withdrawFromStakingAccount: (
    custodyBalance: WHTokenBalance,
    amount: WHTokenBalance,
  ) => Promise<string | undefined>
  castVote: (
    proposalId: string,
    againstVotes: BN,
    forVotes: BN,
    abstainVotes: BN,
  ) => Promise<string | undefined>
  transactionStatus: TransactionStatus
  previousTransactionStatus: TransactionStatus
  refetchBalances: () => Promise<void>
}

const StakeAccountContext = createContext<StakeAccountContextType | undefined>(
  undefined,
)

export function StakeAccountProvider({ children }: { children: ReactNode }) {
  const { showTransactionToast } = useSolanaTransactionToast()
  const { publicKey } = useWallet()
  const [stakeAccount, setStakeAccount] = useState<PublicKey | null>(null)
  const [stakeAccountCustodyAddress, setStakeAccountCustodyAddress] =
    useState<PublicKey | null>(null)
  const {
    getStakeMetadataAddress,
    getStakeAccountCustody,
    createStakeAccount: createStakeAccountTx,
    createStakeAccountAndTransfer: createStakeAccountAndTransferTx,
    transferToStakingAccount: transferToStakingAccountTx,
    withdrawFromStakingAccount: withdrawFromStakingAccountTx,
    castVote: castVoteTx,
    isLoading,
    error,
    transactionStatus,
    previousTransactionStatus,
  } = useMultigovStaking()
  const { balance } = useTokenBalance(publicKey, WORMHOLE_MINT)

  const checkAccount = useCallback(async () => {
    if (!publicKey) return

    const accountData = await getStakeMetadataAddress(publicKey)
    setStakeAccount(accountData || null)
  }, [getStakeMetadataAddress, publicKey])

  const checkStakeAccountCustody = useCallback(async () => {
    if (!publicKey) return

    const accountData = await getStakeAccountCustody(publicKey)
    setStakeAccountCustodyAddress(accountData || null)
  }, [getStakeAccountCustody, publicKey])

  const createStakeAccount = useCallback(async () => {
    const result = await createStakeAccountTx()

    if (result) {
      await checkAccount()
    }

    return result
  }, [createStakeAccountTx, checkAccount])

  const createStakeAccountAndTransfer = useCallback(
    async (amount: string) => {
      const result = await createStakeAccountAndTransferTx(amount)

      if (result) {
        await Promise.all([checkAccount(), checkStakeAccountCustody()])
      }

      return result
    },
    [checkAccount, checkStakeAccountCustody, createStakeAccountAndTransferTx],
  )

  const transferToStakingAccount = useCallback(
    async (amount: string) => {
      return transferToStakingAccountTx(
        WHTokenBalance.fromString(amount).toBN(),
      )
    },
    [transferToStakingAccountTx],
  )

  const withdrawFromStakingAccount = useCallback(
    async (custodyBalance: WHTokenBalance, amount: WHTokenBalance) => {
      const accountData = await getStakeMetadataAddress(publicKey as PublicKey)

      if (!accountData) {
        return
      }

      const result = await withdrawFromStakingAccountTx(
        custodyBalance,
        accountData,
        amount,
      )

      if (result) {
        await Promise.all([checkAccount(), checkStakeAccountCustody()])
      }

      return result
    },
    [
      checkAccount,
      checkStakeAccountCustody,
      withdrawFromStakingAccountTx,
      getStakeMetadataAddress,
      publicKey,
    ],
  )

  const castVote = useCallback(
    async (
      proposalId: string,
      againstVotes: BN,
      forVotes: BN,
      abstainVotes: BN,
    ) => {
      if (!publicKey) return

      const result = await castVoteTx(
        proposalId,
        againstVotes,
        forVotes,
        abstainVotes,
      )

      if (result) {
        return result
      }
    },
    [publicKey, castVoteTx],
  )

  // TODO(dan): improve this -- all it does right now is cause race conditions
  const refetchBalances = useCallback(async () => {
    try {
      await Promise.all([checkAccount(), checkStakeAccountCustody()])
    } catch (error) {
      console.error("Failed to refetch stake account balances:", error)
    }
  }, [checkAccount, checkStakeAccountCustody])

  useEffect(() => {
    if (publicKey) {
      checkAccount()
      checkStakeAccountCustody()
    } else {
      setStakeAccount(null)
      setStakeAccountCustodyAddress(null)
    }
  }, [publicKey, checkAccount, checkStakeAccountCustody])

  useEffect(() => {
    showTransactionToast({
      status: transactionStatus,
      previousStatus: previousTransactionStatus,
      error,
    })
  }, [
    transactionStatus,
    previousTransactionStatus,
    error,
    showTransactionToast,
  ])

  return (
    <StakeAccountContext.Provider
      value={{
        stakeAccount,
        stakeAccountCustodyAddress,
        balance,
        isLoading,
        error,
        checkAccount,
        checkStakeAccountCustody,
        createStakeAccount,
        createStakeAccountAndTransfer,
        transferToStakingAccount,
        withdrawFromStakingAccount,
        castVote,
        transactionStatus,
        previousTransactionStatus,
        refetchBalances,
      }}
    >
      {children}
    </StakeAccountContext.Provider>
  )
}

export function useStakeAccount() {
  const context = useContext(StakeAccountContext)

  if (!context) {
    throw new Error("useStakeAccount must be used within StakeAccountProvider")
  }

  return context
}
