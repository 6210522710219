import { useState, useEffect, useCallback } from "react"
import { useConnection } from "@solana/wallet-adapter-react"
import type { PublicKey } from "@solana/web3.js"
import { getAssociatedTokenAddress } from "@solana/spl-token"
import { createUmi } from "@metaplex-foundation/umi-bundle-defaults"
import {
  fetchMetadata,
  findMetadataPda,
  mplTokenMetadata,
} from "@metaplex-foundation/mpl-token-metadata"
import { publicKey as toUmiPublicKey } from "@metaplex-foundation/umi"

export function useTokenBalance(
  publicKey: PublicKey | null,
  tokenMint: PublicKey,
) {
  const { connection } = useConnection()
  const [balance, setBalance] = useState<number>(0)
  const [symbol, setSymbol] = useState<string>("")
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const solanaNetwork =
    process.env.NEXT_PUBLIC_SOLANA_USE === "devnet"
      ? (process.env.NEXT_PUBLIC_SOLANA_DEVNET_RPC as string)
      : (process.env.NEXT_PUBLIC_SOLANA_RPC as string)

  const getTokenInfo = useCallback(async () => {
    if (!publicKey || !tokenMint) return

    try {
      setIsLoading(true)
      setError(null)

      const tokenAccount = await getAssociatedTokenAddress(
        tokenMint,
        publicKey,
        true,
      )
      const balance = await connection.getTokenAccountBalance(tokenAccount)
      setBalance(
        Number(balance.value.amount) / Math.pow(10, balance.value.decimals),
      )

      const umi = createUmi(solanaNetwork).use(mplTokenMetadata())
      const pda = findMetadataPda(umi, { mint: toUmiPublicKey(tokenMint) })
      const metadata = await fetchMetadata(umi, pda)
      setSymbol(metadata.symbol)
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error("Failed to fetch token info"),
      )
    } finally {
      setIsLoading(false)
    }
  }, [connection, publicKey, tokenMint, solanaNetwork])

  useEffect(() => {
    getTokenInfo()
  }, [getTokenInfo])

  return { balance, symbol, isLoading, error, refetch: getTokenInfo }
}
