import { useToast } from "@chakra-ui/react"
import {
  createContext,
  useContext,
  useRef,
  type ReactNode,
  type ReactElement,
} from "react"

import { Toast } from "common/hooks/useToast"
import { TransactionStatus } from "web3/solana/wormhole-staking/useMultigovStaking"
import MiningToast from "web3/components/toasts/MiningToast"

type SolanaTransactionToastContextType = {
  showTransactionToast: (params: {
    status: TransactionStatus
    previousStatus: TransactionStatus
    error?: Error | null
    onComplete?: () => void
    onSuccess?: () => void
    onFail?: () => void
  }) => void
}

type ShowTransactionToastParams = {
  status: TransactionStatus
  previousStatus: TransactionStatus
  error?: Error | null
  onComplete?: () => void
  onSuccess?: () => void
  onFail?: () => void
}

const SolanaTransactionToastContext = createContext<
  SolanaTransactionToastContextType | undefined
>(undefined)

export function SolanaTransactionToastProvider({
  children,
}: {
  children: ReactNode
}) {
  const toastRef = useRef<string | number | undefined>()
  const toast = useToast({
    duration: null,
    position: "bottom-right",
  })

  const showTransactionToast = ({
    status,
    previousStatus,
    error,
    onComplete,
    onSuccess,
    onFail,
  }: ShowTransactionToastParams) => {
    if (
      status === TransactionStatus.NONE &&
      previousStatus === TransactionStatus.NONE
    ) {
      return
    }

    const renderToast = (children: ReactElement) => {
      toastRef.current = toast({
        render: () => <>{children}</>,
      })
    }

    switch (status) {
      case TransactionStatus.CREATE_ACCOUNT:
        renderToast(
          <MiningToast
            description="Please confirm the transaction in your wallet"
            title="Create an account"
          />,
        )

        if (onComplete) {
          onComplete()
        }

        return

      case TransactionStatus.CREATE_AND_TRANSFER:
        renderToast(
          <MiningToast
            description="Please confirm the transaction in your wallet"
            title="Create an account and fund it"
          />,
        )

        if (onComplete) {
          onComplete()
        }

        return

      case TransactionStatus.DELEGATE:
        renderToast(
          <MiningToast
            description="Please confirm the transaction in your wallet"
            title="Delegate"
          />,
        )

        if (onComplete) {
          onComplete()
        }

        return

      case TransactionStatus.WITHDRAW:
        renderToast(
          <MiningToast
            description="Please confirm the transaction in your wallet"
            title="Withdraw from staking account"
          />,
        )

        if (onComplete) {
          onComplete()
        }

        return

      case TransactionStatus.TRANSFER_TO_STAKE_ACCOUNT:
        renderToast(
          <MiningToast
            description="Please confirm the transaction in your wallet"
            title="Transfer to stake account"
          />,
        )

        if (onComplete) {
          onComplete()
        }

        return

      case TransactionStatus.CAST_VOTE:
        renderToast(
          <MiningToast
            description="Please confirm the transaction in your wallet"
            title="Cast vote"
          />,
        )

        if (onComplete) {
          onComplete()
        }

        return

      case TransactionStatus.SUCCESS: {
        toast.closeAll()

        toastRef.current = toast({
          duration: 5000,
          render: ({ onClose }) => (
            <Toast
              description="Transaction complete"
              status="success"
              title="Success!"
              onClose={onClose}
            />
          ),
        })

        if (onSuccess) {
          onSuccess()
        }

        return
      }

      case TransactionStatus.FAIL:
        toast.closeAll()

        if (error?.name === "WalletSendTransactionError") {
          toastRef.current = toast({
            duration: 5000,
            render: ({ onClose }) => (
              <Toast
                description="Something went wrong"
                status="error"
                title="Transaction failed"
                onClose={onClose}
              />
            ),
          })
        }

        if (onFail) {
          onFail()
        }

        return

      default:
        break
    }
  }

  return (
    <SolanaTransactionToastContext.Provider value={{ showTransactionToast }}>
      {children}
    </SolanaTransactionToastContext.Provider>
  )
}

export function useSolanaTransactionToast() {
  const context = useContext(SolanaTransactionToastContext)

  if (!context) {
    throw new Error(
      "useSolanaTransactionToast must be used within SolanaTransactionToastProvider",
    )
  }

  return context
}
