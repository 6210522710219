import type { FC, ReactNode } from "react"
import React, { useState, useEffect, useContext, createContext } from "react"
import type {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query"

import type { MeQuery, MeQueryVariables } from "query/graphql"
import { MeDocument } from "query/graphql"
import { useSession } from "session/providers/SessionProvider"
import { useLogout } from "session/hooks/useLogout"
import { useQuery } from "query/hooks/useQuery"
import { ROUTES } from "common/constants/routes"
import { useRouter } from "common/hooks/useRouter"
import { Chain, useMultichainAccount } from "web3/hooks/useMultichainAccount"

export type Me = MeQuery["me"]

type Value = {
  me: Me | undefined
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<MeQuery, unknown>>
}

const PRIVATE_PAGES = ["/user/settings", "/user/your-daos", "/user/welcome"]

const MeContext = createContext<Value>(
  // @ts-expect-error It's a good practice not to give a default value even though the linter tells you so
  {},
)

export const MeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [me, setMe] = useState<Me | undefined>(undefined)
  const { isLoggedIn } = useSession()
  const { logout } = useLogout()
  const { asPath } = useRouter()

  const { refetch } = useQuery<MeQuery, MeQueryVariables>(
    {
      query: MeDocument,
    },
    {
      enabled: isLoggedIn,
      onSuccess: (data) => {
        setMe(data?.me)
      },
    },
  )

  const { multichainAccount, isInitialized } = useMultichainAccount({
    // connect wallet through Metamask
    onConnect({ address: nextAddress }) {
      if (multichainAccount.chain === Chain.SOL) {
        return
      }

      if (isLoggedIn && nextAddress !== address && isInitialized) {
        const redirectTo = PRIVATE_PAGES.includes(asPath)
          ? ROUTES.explore()
          : undefined

        setMe(undefined)
        logout(redirectTo)
      }
    },
  })

  const { address } = multichainAccount

  useEffect(() => {
    if (!isInitialized) return

    // logout
    if (!address || !isLoggedIn) {
      setMe(undefined)

      return
    }

    if (address && isLoggedIn) {
      refetch()
    }
  }, [address, refetch, isLoggedIn, isInitialized])

  useEffect(() => {
    if (!isInitialized) return

    // disconnect wallet
    if (!address && me) {
      const redirectTo = PRIVATE_PAGES.includes(asPath)
        ? ROUTES.explore()
        : undefined

      setMe(undefined)
      logout(redirectTo)

      return
    }
  }, [address, asPath, logout, me, isInitialized])

  useEffect(() => {
    if (!isInitialized) return

    // switch wallet
    if (me && me.type === "EOA" && address !== me.address) {
      const redirectTo = PRIVATE_PAGES.includes(asPath)
        ? ROUTES.explore()
        : undefined

      setMe(undefined)
      logout(redirectTo)
    }
  }, [address, asPath, logout, me, isInitialized])

  return (
    <MeContext.Provider value={{ me, refetch }}>{children}</MeContext.Provider>
  )
}

export const useMe = (): Me | undefined => {
  const meContext = useContext(MeContext)

  if (!meContext) {
    throw new Error("useMe must be used within MeContext")
  }

  const { me } = meContext

  return me
}

export const useRefetchMe = (): Value["refetch"] => {
  const meContext = useContext(MeContext)

  if (!meContext) {
    throw new Error("useMe must be used within MeContext")
  }

  const { refetch } = meContext

  return refetch
}
