import type { AppProps } from "next/app"
import type { Scope } from "@sentry/nextjs"
import Head from "next/head"
import Router, { useRouter } from "next/router"
import NProgress from "nprogress"
import type { FC } from "react"
import { useState } from "react"
import { ChakraProvider } from "@chakra-ui/react"
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"
import "ui/styles/nprogress.css"
import Script from "next/script"
import * as Sentry from "@sentry/nextjs"
import { WagmiConfig } from "wagmi"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { SpeedInsights } from "@vercel/speed-insights/react"
import { isMobile } from "react-device-detect"
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react"
import type { Adapter } from "@solana/wallet-adapter-base"

import "theme/global/styles.css"
import "ui/styles/mdxeditor.css"
import type { ThirdPartyError } from "common/components/ErrorBoundary"
import ErrorBoundary, {
  addThirdPartyContexts,
  ErrorType,
  Severity,
  toFormattedString,
} from "common/components/ErrorBoundary"
import { TransactionProvider } from "web3/providers/TransactionProvider"
import { TransactionToastProvider } from "web3/providers/TransactionToastProvider"
import { isProduction } from "common/helpers/environment"
import { NavegableModalProvider } from "common/providers/NavegableModalProvider"
import { ModalsProvider } from "common/hooks/useModals"
import { SessionProvider } from "session/providers/SessionProvider"
import { MeProvider } from "user/providers/MeProvider"
import { wagmiClient } from "web3/constants/wagmi"
import { SignerProvider } from "web3/providers/SignerProvider"
import { getTheme } from "theme"
import { useEIP6963Store } from "web3/stores/eip6963"
import { StakeAccountProvider } from "web3/solana/wormhole-staking/StakeAccountProvider"
import { SolanaTransactionToastProvider } from "web3/solana/providers/SolanaTransactionToastProvider"

Router.events.on("routeChangeError", () => NProgress.done())
Router.events.on("routeChangeComplete", () => NProgress.done())
Router.events.on("routeChangeStart", () => NProgress.start())

const App: FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter()
  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache(),
        defaultOptions: {
          queries: {
            useErrorBoundary: true,
            refetchOnWindowFocus: false,
          },
          mutations: {
            useErrorBoundary: true,
          },
        },
      }),
  )

  function extractGovernanceId(redirectUrl: string | undefined): string | null {
    if (!redirectUrl) return null

    const governanceIdRegex = /\/gov\/([^/]+)/
    const match = redirectUrl.match(governanceIdRegex)

    return match ? match[1] : null
  }

  const governanceId =
    router.route === "/user/connect"
      ? extractGovernanceId(router.query?.redirect as string)
      : router.query?.governanceId

  const theme = getTheme(governanceId as string)

  const solanaNetwork =
    process.env.NEXT_PUBLIC_SOLANA_USE === "devnet"
      ? (process.env.NEXT_PUBLIC_SOLANA_DEVNET_RPC as string)
      : (process.env.NEXT_PUBLIC_SOLANA_RPC as string)

  // `WalletProvider` recommends using `StandardWalletAdapter` which is able to
  // check to browser's currently installed wallet extensions. rather than
  // passing in a hard-coded list, we can use this similar to eip6963. however,
  // it still expects an array of wallets passed in, so give it an empty array.
  const solanaWallets: Adapter[] = []

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const eip6963Providers = useEIP6963Store()

  return (
    <>
      <Head>
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      </Head>
      <ErrorBoundary section="root">
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV === "development" ? (
            <ReactQueryDevtools initialIsOpen />
          ) : null}
          <ChakraProvider theme={theme}>
            <NavegableModalProvider>
              <ModalsProvider>
                <ConnectionProvider endpoint={solanaNetwork}>
                  <WalletProvider autoConnect wallets={solanaWallets}>
                    <SolanaTransactionToastProvider>
                      <StakeAccountProvider>
                        <WagmiConfig client={wagmiClient}>
                          <SessionProvider>
                            <SignerProvider>
                              <MeProvider>
                                <TransactionProvider>
                                  <TransactionToastProvider>
                                    <>
                                      <Scripts />
                                      <Component {...pageProps} />
                                      <SpeedInsights
                                        route={router.pathname}
                                        sampleRate={0.2}
                                      />
                                    </>
                                  </TransactionToastProvider>
                                </TransactionProvider>
                              </MeProvider>
                            </SignerProvider>
                          </SessionProvider>
                        </WagmiConfig>
                      </StakeAccountProvider>
                    </SolanaTransactionToastProvider>
                  </WalletProvider>
                </ConnectionProvider>
              </ModalsProvider>
            </NavegableModalProvider>
          </ChakraProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </>
  )
}

export default App

const Scripts = () => {
  return (
    <>
      {isProduction ? (
        <>
          {/* Google analytics*/}
          <Script
            async
            id="google-anaylitics-config-script"
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID}`}
            strategy="afterInteractive"
            type="text/javascript"
            onError={(_error) => {
              const error: ThirdPartyError = {
                type: ErrorType.ThirdParty,
                context: {
                  error: _error,
                  from: "google-anaylitics-config-script",
                },
              }

              Sentry.captureException(
                new Error(toFormattedString(error)),
                (scope) => {
                  scope.setLevel(Severity.Warning)
                  addThirdPartyContexts(scope as Scope, error)

                  return scope
                },
              )
            }}
          />

          <Script
            dangerouslySetInnerHTML={{
              __html: `var w=window;w.dataLayer = w.dataLayer || [];function gtag(){w.dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID}');`,
            }}
            id="google-anaylitics-script"
            strategy="afterInteractive"
            type="text/javascript"
            onError={(_error) => {
              const error: ThirdPartyError = {
                type: ErrorType.ThirdParty,
                context: {
                  error: _error,
                  from: "google-anaylitics-script",
                },
              }

              Sentry.captureException(
                new Error(toFormattedString(error)),
                (scope) => {
                  scope.setLevel(Severity.Warning)
                  addThirdPartyContexts(scope as Scope, error)

                  return scope
                },
              )
            }}
          />

          {/* Intercom */}
          <Script
            dangerouslySetInnerHTML={{
              __html: `(function(){var APP_ID="${
                process.env.NEXT_PUBLIC_INTERCOM_APP_ID
              }";var w=window;w.intercomSettings={app_id: APP_ID, custom_launcher_selector: '#open_web_chat', hide_default_launcher: ${
                isMobile ? "true" : "false"
              }};var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
            }}
            id="intercom-script"
            strategy="afterInteractive"
            type="text/javascript"
            onError={(_error) => {
              const error: ThirdPartyError = {
                type: ErrorType.ThirdParty,
                context: {
                  error: _error,
                  from: "intercom-script",
                },
              }

              Sentry.captureException(
                new Error(toFormattedString(error)),
                (scope) => {
                  scope.setLevel(Severity.Warning)
                  addThirdPartyContexts(scope as Scope, error)

                  return scope
                },
              )
            }}
          />
        </>
      ) : null}
    </>
  )
}
