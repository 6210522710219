import type { GetServerSidePropsContext } from "next"
import nookies, { parseCookies } from "nookies"

enum Cookie {
  Token = "token",
  Features = "features",
  NewLayout = "newlayout",
}

const getCookie = (
  context: GetServerSidePropsContext,
  key: Cookie,
): undefined | string => {
  const cookies = nookies.get(context)
  const cookie = cookies?.[key]

  if (!cookie) return undefined

  return cookie
}

export const getSessionToken = (
  context?: GetServerSidePropsContext,
): string | undefined => {
  if (context) {
    return context ? getCookie(context, Cookie.Token) : undefined
  } else {
    const cookies = parseCookies()

    return cookies?.token ?? undefined
  }
}

export const parseSessionToken = (context: GetServerSidePropsContext) => {
  try {
    const token = getSessionToken(context)

    if (!token) {
      return
    }

    const [headerB64, payloadB64] = token.split(".")

    const decodeBase64 = (b64: string): string => {
      const base64 = b64.replace(/-/g, "+").replace(/_/g, "/")
      const padding = "=".repeat((4 - (base64.length % 4)) % 4)

      return Buffer.from(base64 + padding, "base64").toString("utf-8")
    }

    const header = JSON.parse(decodeBase64(headerB64))
    const payload = JSON.parse(decodeBase64(payloadB64))

    return { header, payload }
  } catch (error) {
    throw new Error("Invalid JWT format")
  }
}
